.tiles {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 32px;
  margin-top: 16px;
  color: var(--default-opaque-neutrals-gray-80, #4A4A4A);
  font-size: 16px;
  font-weight: 300;
  line-height: 150%;
  width: 927px;

  p {
    margin: 0;
  }
}

@media screen and (max-width: 980px) {
  .tiles {
    grid-template-columns: 1fr;
    width: 90%;
  }
}

@media screen and (max-width: 512px) {
  .subtitle {
    display: none;
  }
}
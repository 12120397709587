.fonts-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 32px;
}

.search {
  margin-top: 64px;
}

.row {
  margin-top: 64px;
  margin-bottom: 64px;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.dropdown-name {
  color: white;
}

.progress-slider-name {
  color: white;
}

@media screen and (max-width: 979px) {
  .fonts-container {
    grid-template-columns: 1fr 1fr;
  }

  .small-container {
    width: 90%;
  }
}

@media screen and (max-width: 662px) {
  .fonts-container {
    grid-template-columns: 1fr;
  }

  .row {
    flex-direction: column;
    gap: 32px;
  }
}

.mono {
  font-family:
  "SFMono-Regular",
  Consolas,
  "Liberation Mono",
  Menlo,
  Courier,
  monospace!important;

  pre {
    font-family:
    "SFMono-Regular",
    Consolas,
    "Liberation Mono",
    Menlo,
    Courier,
    monospace!important;
  }
}

.zalgo {
  line-height: 350%!important;
}

.container {
  width: 1168px;
  margin: 0 auto;
  padding: 64px 0 48px;
  display: flex;
  gap: 48px;
  flex-direction: column;
  align-items: center;
}

.row {
  display: flex;
  gap: 32px;
}

.card {
  padding: 24px;
  width: 368px;
  height: 230px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  border-radius: 10px;
  background: var(--default-opaque-neutrals-gray-5, #F2F2F2);
  box-sizing: border-box;
}

.title {
  color: var(--default-opaque-neutrals-gray-80, #4A4A4A);
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  margin: 0;
}

.text {
  color: var(--default-opaque-neutrals-gray-80, #4A4A4A);
  font-size: 16px;
  font-weight: 300;
  line-height: 150%;
  margin: 0;
}

.link {
  color: inherit;
}

@media screen and (max-width: 1185px) {
  .row {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 32px;
  }

  .card {
    width: auto;
    height: auto;
    min-height: 230px;
  }

  .container {
    width: 100%;
    padding: 64px 16px;
    box-sizing: border-box;
  }
}

@media screen and (max-width: 723px) {
  .row {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 32px;
  }
}
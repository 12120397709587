.container {
  display: flex;
  align-items: center;
}

.text {
  margin: 0;
  color: var(--default-opaque-neutrals-gray-80, #4A4A4A);
  font-size: 16px;
  font-weight: 600;
  line-height: 150%;
}

@media screen and (max-width: 396px) {
  .container {
    flex-direction: column;
    gap: 8px!important;
    align-items: flex-start;
  }
}

// @media screen and (max-width: 600px) {
//   .container .text {
//     color: white;
//   }
// }
@media screen and (max-width: 608px) {
  .subtitle {
    display: none;
  }
}

.citations {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  text-wrap: nowrap;
}

.double {
  pre {
    line-height: 131%;
  }
}
.container {
  width: 90%;
  max-width: 927px;
  display: flex;
  overflow-x: auto;
}

.small-container {
  width: 927px;
}

.table {
  display: grid;
  font-size: 16px;
  line-height: 150%;
  font-weight: 300;
  color: var(--default-opaque-neutrals-gray-80, #4A4A4A);
  width: 100%;
}

.header-cell {
  font-weight: 600;
}

.cell {
  border-bottom: 1px solid #ACADAF;
  height: 56px;
  margin: auto;
  width: 100%;
  padding-left: 24px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  text-wrap: nowrap;
  white-space: nowrap;
}

.header {
  background: var(--colors-base, #F2F2F2);
  border-bottom: 1px solid #ACADAF;
  color: var(--components-forms-form-text, #6D6E70);
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  height: 56px;
  display: flex;
  align-items: center;
  padding-left: 24px;
}
.table {
  width: 927px;
  grid-template-rows: repeat(auto-fill, minmax(55px, auto));

  span {
    padding: 0;
    height: 100%;
    text-wrap: wrap;
    white-space: normal;
  }

  span:nth-child(3n - 1) {
    justify-content: center;
  }

  span:nth-child(3n) {
    padding: 16px 24px;
  }

  span:nth-child(3n - 2) {
    padding-left: 24px;
  }
}

@media screen and (max-width: 832px) {
  .subtitle {
    display: none;
  }
}
.fonts-container {
  margin-top: 64px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 32px;
}

@media screen and (max-width: 760px) {
  .fonts-container {
    grid-template-columns: 1fr;
  }
}
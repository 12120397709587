.container {
  width: 100%;
  display: flex;
  justify-content: center;
  // margin-top: 64px;
}

.small-container {
  width: 927px;
  display: flex;
  gap: 64px;
  justify-content: space-between;
  align-items: center;
}

.text {
  color: var(--default-opaque-neutrals-gray-80, #4A4A4A);
  font-size: 16px;
  font-weight: 300;
  line-height: 150%;

  & > *:not(:first-child) {
    margin-top: 16px;
  }
}

.text + .text {
  margin-top: 16px;
}

.subtitle {
  color: var(--color-text, #4A4A4A);
  font-size: 20px;
  font-weight: 300;
  line-height: 150%;
}

.text-container {
  display: flex;
  flex-direction: column;
  width: 464px;
}

@media screen and (max-width: 1000px) {
  .small-container {
    flex-direction: column;
    width: 90%;

    .text-container {
      width: 100%;
    }
  }
}

@media screen and (max-width: 480px) {
  .title {
    font-weight: 300;
  }

  .small-container svg {
    width: 100%!important;
    height: auto!important;
  }
}
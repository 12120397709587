@media screen and (max-width: 654px) {
  .subtitle {
    display: none;
  }
}

.citations {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  text-wrap: nowrap;
}
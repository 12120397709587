.container {
  width: 100%;
  min-height: 441px;
  position: relative;
}

.small-container {
  display: flex;
  flex-direction: column;
  width: 927px;
  align-items: center;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 88px;
}

.header-image {
  width: 100%;
  min-height: 441px;
  position: absolute;
  background-repeat: no-repeat;
  background-position-x: center;
  z-index: -99999;
  background-position-y: bottom;
  background-image: url('../../assets/images/font-generator-header.svg');
  background-size: cover;
}

.subtitle {
  color: var(--default-opaque-neutrals-white, #FFF);
  text-align: center;
  font-size: 24px;
  font-weight: 300;
  line-height: 130%;
  width: 918px;
  margin: 0 auto;
}

.breadcrumbs {
  position: absolute;
}

@media screen and (max-width: 1000px) {
  .small-container {
    width: 90%;
  }

  .subtitle {
    width: 80%;
  }

  // .title {
  //   width: 90%;
  // }
}

@media screen and (max-width: 500px) {
  .title {
    font-size: 48px;
  }
}
.container {
  box-sizing: border-box;
  border-radius: var(--specs-spacing-icon-button-padding-small, 8px);
  background: var(--default-opaque-neutrals-white, #FFF);
  box-shadow: 0px 1px 10px 0px rgba(29, 29, 32, 0.08), 0px 4px 5px 0px rgba(29, 29, 32, 0.05), 0px 2px 4px -1px rgba(29, 29, 32, 0.16);

  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 0 24px 0;

  width: 444px;
  height: 120px;

  text-decoration: none!important;

  b {
    color: #4A4A4A;
    font-size: 18px;
    font-weight: 600;
    line-height: 27px;
    letter-spacing: 0px;
  }

  small {
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
    letter-spacing: 0px;
    color: #4A4A4A;
  }
}

.column {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.icon {
  font-size: 48px;
}

@media screen and (max-width: 1000px) {
  .container {
    width: 330px;
  }
}

@media screen and (max-width: 368px) {
  .container {
    width: 100%;
  }
}
.title {
  color: var(--default-opaque-neutrals-gray-80, #4A4A4A);
  font-weight: 300;
  line-height: 130%;
  margin: 0;
  width: 100%;
}

@media screen and (max-width: 1000px) {
  .size-2, .size-3, .size-4, .size-5 {
    width: 90%;
  }
}

.size-2, .size-3, .size-4, .size-5 {
  max-width: 927px;
}

.size-1 {
  color: var(--colors-base-light, #FFF);
  font-size: 56px;
  font-weight: 600;
}

.size-2 {
  font-size: 48px;
}

.size-3 {
  font-size: 32px;
}

.size-4 {
  font-size: 24px;
  font-weight: 600;
}

.size-5 {
  font-size: 20px;
  font-weight: 600;
}
.input {
  color: var(--default-opaque-neutrals-gray-80, #4A4A4A);
  margin-top: 48px;
  display: flex;
  width: 918px;
  height: 56px;
  padding-left: 18px;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid #D9D9D9;
  background: #FFF;
  box-shadow: 0px 1px 10px 0px rgba(29, 29, 32, 0.08), 0px 4px 5px 0px rgba(29, 29, 32, 0.05), 0px 2px 4px -1px rgba(29, 29, 32, 0.16);
  resize: none;
  font-family: -apple-system, system-ui, -apple-system, "Segoe UI", Roboto, Ubuntu, Cantarell, "Noto Sans", sans-serif, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif;
}

.input::placeholder {
  font-family: -apple-system, system-ui, -apple-system, "Segoe UI", Roboto, Ubuntu, Cantarell, "Noto Sans", sans-serif, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif;
  color: var(--default-opaque-neutrals-gray-30, var(--colors-default-opaque-neutrals-gray-30, #ACADAF));
  font-size: 16px;
  font-weight: 300;
  line-height: 150%;
}

@media screen and (max-width: 988px) {
  .input {
    width: 100%;
  }
}
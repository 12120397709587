.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  margin-top: 48px;
  padding: 64px 0;
  width: 100%;
  display: flex;
  justify-content: center;
  background: var(--light-purple, #F8F5FF);
}

.small-container {
  display: flex;
  flex-direction: column;
  width: 927px;
  align-items: center;
}

.secondary-text {
  color: var(--default-opaque-neutrals-gray-80, #4A4A4A);
  font-size: 16px;
  font-weight: 300;
  line-height: 150%;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 12px;
  white-space: pre;
  text-wrap: wrap!important;
  white-space: normal;
}

.icon-and-text-container {
  margin-top: 64px;
  width: 927px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 32px;
}

.icon-and-text {
  display: flex;
  gap: 24px;
  width: 448px;
}

.icon-and-text-text {
  width: 360px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.text-with-image-title {
  color: #4A4A4A;
  font-size: 20px;
  font-weight: 600;
  line-height: 150%;
}

.subtitle {
  margin-top: 8px;
  color: #4A4A4A;
  font-size: 18px;
  font-weight: 300;
  line-height: 150%;
  width: 100%;
}

@media screen and (max-width: 1000px) {
  .small-container {
    width: 90%;
  }

  .icon-and-text-container {
    grid-template-columns: 1fr;
    width: 100%;
  }

  .icon-and-text {
    width: 100%;
  }
}

@media screen and (max-width: 500px) {
  .icon-and-text {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
}

@media screen and (max-width: 400px) {
  .icon-and-text-text {
    width: 100%;
  }
}
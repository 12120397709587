.container {
  margin-top: 64px;
  width: 100%;
  background-image: url('./assets/bg.svg');
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding: 64px 0;
}

.section {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
}

.text-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 960px;
  box-sizing: border-box;
}

.text {
  margin: 0;
  color: var(--default-opaque-neutrals-white, #FFF);
  font-size: 18px;
  font-weight: 300;
  line-height: 150%;
}

.link {
  color: inherit;
}

.logos {
  margin-top: 48px;
  display: flex;
  gap: 32px;
  justify-content: center;
  flex-wrap: wrap;
  padding: 0 16px;
}

.logo-container {
  height: 24px;

  img:nth-of-type(1) {
    height: 24px;
    display: none;
    max-width: 100%;
  }

  img:nth-of-type(2) {
    height: 24px;
    display: block;
    max-width: 100%;
  }

  &:hover {
    img:nth-of-type(1) {
      display: block;
    }

    img:nth-of-type(2) {
      display: none;
    }
  }
}

@media screen and (max-width: 768px) {
  .section > h2 {
    font-size: 32px;
  }
}

@media screen and (max-width: 1000px) {
  .text-container {
    width: 100%;
    padding: 0 16px;
  }
}
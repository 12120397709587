.mono {
  font-family:
  "SFMono-Regular",
  Consolas,
  "Liberation Mono",
  Menlo,
  Courier,
  monospace!important;

  pre {
    font-family:
    "SFMono-Regular",
    Consolas,
    "Liberation Mono",
    Menlo,
    Courier,
    monospace!important;
  }
}

.calendar {
  white-space: revert;
}

.zalgo {
  line-height: 350%!important;
}

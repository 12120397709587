.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  position: relative;
}

.small-container {
  display: flex;
  flex-direction: column;
  width: 927px;
  align-items: center;
}

.variants-container {
  margin-top: 64px;
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.header-image {
  min-height: 500px!important;
  background-image: url('../../assets/images/font-generator-header.svg');
}

.header-container {
  min-height: 500px!important;
}

.subtitle {
  color: var(--default-opaque-neutrals-white, #FFF);
  text-align: center;
  font-size: 24px;
  font-weight: 300;
  line-height: 130%;
  width: 918px;
  margin: 0 auto;
}

.progress-bar {
  margin-top: 64px;
  width: 100%;
  display: flex;
  justify-content: end;
}

.row {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 64px;

  .progress-bar {
    width: auto;
    margin-top: 0;
  }
}

.card {
  pre {
    margin-top: 16px;
  }
}

@media screen and (max-width: 988px) {
  .small-container {
    width: 90%;
  }

  .subtitle {
    width: 80%;
  }

  .card {
    width: 100%;
  }

  .variants-container {
    width: 100%;
  }
}

@media screen and (max-width: 600px) {
  .row {
    margin-top: 32px;
    flex-direction: column;
    gap: 8px;
    color: white!important;

    p {
      color: white!important;
    }

    & > div {
      justify-content: start;
    }
  }

  .progress-bar {
    margin-top: 32px;
    justify-content: start;
  }
}
.table {
  span {
    padding: 0;
  }

  span:nth-child(3n - 1) {
    justify-content: center;
  }
  
  span:nth-child(3n - 2), span:nth-child(3n) {
    padding-left: 24px;
  }
}

@media screen and (max-width: 766px) {
  .subtitle {
    display: none;
  }
}
.container {
  width: 927px;
}

.tile {
  width: 568px;
  border-radius: 10px;
  border: 1px solid var(--borders-border, #D6D6D6);
  background: #FFF;
  padding: 24px;

  color: var(--default-opaque-neutrals-gray-80, #4A4A4A);
  font-size: 18px;
  font-weight: 300;
  line-height: 40px;
}

@media screen and (max-width: 1000px) {
  .container {
    width: 90%;
  }
}

@media screen and (max-width: 700px) {
  .tile {
    width: 100%;
    box-sizing: border-box;
  }
}

.container {
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.20);
  background: var(--default-opaque-primary-primary-50, #D3321F);
  height: 44px;
  color: var(--default-opaque-neutrals-white, #FFF);
  font-size: 16px;
  font-weight: 600;
  line-height: 150%;
  padding: 0 12px;
  cursor: pointer;
}

.secondary {
  height: 32px;
  background: var(--default-opaque-secondary-secondary-50, #3D7B7B);
  padding: 0 8px;
  font-size: 14px;
}
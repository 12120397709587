.arrow {
  color: var(--core-white-white-100, #FFF);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  opacity: 0.7;
}

.container {
  height: 40px;
  background: rgba(109, 110, 112, 0.30);
  padding: 0 16px;
  display: flex;
  gap: 8px;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  overflow-x: auto;
}

.link {
  opacity: 0.7;
  color: var(--core-white-white-100, #FFF);
  font-size: 16px;
  font-style: normal;
  line-height: 150%;
  text-decoration: none;
  cursor: pointer;
  text-wrap: nowrap;
  white-space: nowrap;
  &:hover {
    text-decoration: underline;
  }
}

.link:last-child {
  opacity: 1;
  cursor: initial;
  font-weight: 600;
  &:hover {
    text-decoration: none;
  }
}

/* Hide scrollbar for Chrome, Safari and Opera */
.container::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.container {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  position: relative;
}

.small-container {
  display: flex;
  flex-direction: column;
  width: 927px;
  align-items: center;
}

.variants-container {
  margin-top: 64px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-items: center;
}

.header-image {
  background-image: url('../../../assets/images/font-generator-header.svg');
}

.preview-size {
  margin: 64px 0;
  width: 100%;
  display: flex;
  justify-content: end;
}

.first-row {
  display: flex;
  gap: 32px;
  justify-content: center;
  width: 100%;
}

.progress-slider-name {
  color: var(--text-colors-base-text, #6D6E70);
  font-size: 16px;
  font-weight: 600;
  line-height: 150%;
}

.second-row {
  justify-content: center;
  margin-top: 42px;
  display: flex;
  gap: 32px;
}

.card-text {
  margin-top: 32px;
  color: #4A4A4A;
  font-size: 16px;
  font-weight: 300;
  line-height: 450%;
}

.question-circle {
  border-radius: 999px;
  background: var(--colors-gray, #6D6E70);
  height: 28px;
  width: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

@media screen and (max-width: 988px) {
  .small-container {
    width: 90%;
  }

  .subtitle {
    width: 80%;
  }

  .card {
    width: 90%;
  }
}

@media screen and (max-width: 600px) {
  .row {
    margin-top: 32px;
    flex-direction: column;
    gap: 8px;
    color: white!important;

    p {
      color: white!important;
    }

    & > div {
      justify-content: start;
    }
  }
}

@media screen and (max-width: 850px) {
  .first-row {
    flex-direction: column;
    justify-content: start;
  }

  .preview-size {
    justify-content: start;
  }

  .progress p {
    color: #6d6e70!important;
  }
}

@media screen and (max-width: 795px) {
  .second-row {
    flex-direction: column;
    justify-content: start;
    width: 100%;
  }
}

@media screen and (max-width: 615px) {
  .subtitle {
    display: none;
  }
}

.mono {
  font-family:
  "SFMono-Regular",
  Consolas,
  "Liberation Mono",
  Menlo,
  Courier,
  monospace!important;

  pre {
    font-family:
    "SFMono-Regular",
    Consolas,
    "Liberation Mono",
    Menlo,
    Courier,
    monospace!important;
  }
}

.container {
  box-sizing: border-box;
  border-radius: var(--specs-spacing-icon-button-padding-small, 8px);
  background: var(--default-opaque-neutrals-white, #FFF);
  box-shadow: 0px 1px 10px 0px rgba(29, 29, 32, 0.08), 0px 4px 5px 0px rgba(29, 29, 32, 0.05), 0px 2px 4px -1px rgba(29, 29, 32, 0.16);

  display: flex;
  flex-direction: column;
  padding: 32px 24px;

  width: 927px;
  min-height: 136px;

  text-decoration: none!important;

  b {
    color: var(--default-opaque-neutrals-gray-80, #4A4A4A);
    font-size: 18px;
    font-weight: 600;
    line-height: 150%;
  }
}

.left {
  display: flex;
  flex-direction: column;
}

.copy-icon {
  width: 32px;
  height: 32px;
  cursor: pointer;
}

.subtitle {
  color: var(--default-opaque-neutrals-gray-80, #4A4A4A);
  font-size: 16px;
  font-weight: 300;
  line-height: 150%;
}

.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.inner-row {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.text {
  margin: 0;
  // text-wrap: wrap;
  color: var(--default-opaque-neutrals-gray-80, #4A4A4A);
  font-size: 16px;
  font-weight: 300;
  line-height: 126%;
  font-family: -apple-system, system-ui, -apple-system, "Segoe UI", Roboto, Ubuntu, Cantarell, "Noto Sans", sans-serif, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif;
  overflow: hidden;
  text-overflow: ellipsis;
}

.additional-text {
  color: var(--default-opaque-neutrals-gray-80, #4A4A4A);
  font-size: 16px;
  font-weight: 300;
  line-height: 150%;
}

.text-to-show {
  color: var(--default-opaque-neutrals-gray-80, #4A4A4A);
  font-size: 16px;
  font-weight: 300;
  line-height: 150%;
  margin: auto;
}
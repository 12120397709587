.container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.list {
  display: flex;
  gap: 32px;
  width: 927px;
  margin: 0 auto;
  margin-top: 64px;
  list-style: none;
  padding: 0;
  flex-wrap: wrap;
}

.option {
  border-radius: 5px;
  background: var(--default-opaque-neutrals-gray-50, #737373);
  padding: 2px 8px;
  color: var(--default-opaque-neutrals-gray-5, #F2F2F2);
  font-size: 14px;
  font-weight: 300;
  line-height: 150%;
  text-transform: uppercase;
  text-decoration: none;
  width: 100%;
  text-wrap: nowrap;
}

@media screen and (max-width: 1000px) {
  .list {
    width: 90%;
  }
}

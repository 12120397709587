.title {
  margin-bottom: 16px;
}

.subtitle {
  margin-bottom: 12px;
}

.text {
  color: var(--default-opaque-neutrals-gray-80, #4A4A4A);
  font-size: 16px;
  font-weight: 300;
  line-height: 150%;

  b {
    font-weight: 600;
  }

  & > *:not(:first-child) {
    margin-top: 16px;
  }
}

.container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.small-container {
  width: 100%;
  max-width: 927px;
}

@media screen and (max-width: 1000px) {
  .small-container {
    width: 90%;
  }
}

.bigger-text {
  font-size: 18px;
}
.container {
  display: flex;
  gap: 8px;
  align-items: center;
}

.name {
  color: var(--text-colors-base-text, #6D6E70);
  font-size: 16px;
  font-weight: 600;
  line-height: 36px;
}

.dropdown {
  cursor: pointer;
  color: var(--default-opaque-neutrals-gray-80, #4A4A4A);
  font-size: 16px;
  font-weight: 600;
  line-height: 36px;

  display: flex;
  min-width: 150px;
  height: 38px;
  padding: 0 8px 0 12px;
  justify-content: space-between;
  align-items: center;

  border-radius: 5px;
  border: 1px solid var(--default-opaque-neutrals-gray-30, #ACADAF);
  background: var(--default-opaque-neutrals-white, #FFF);
  padding-right: 35px;
}

.small {
  .name {
    color: var(--default-opaque-neutrals-gray-80, #4A4A4A);
  }

  .dropdown {
    height: 32px;
  }
}
.react-select *:focus {
  outline: none!important;
  border: none!important;
}
.react-select svg {
  display: none!important;
}
.container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.small-container {
  margin-top: 64px;
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.title {
  font-weight: 400;
}

.text {
  margin-top: 32px;
  white-space: normal;
}

@media screen and (max-width: 1000px) {
  .small-container {
    width: 90%;

    a {
      width: 100%;
    }
  }
}
